@font-face {
  font-family: 'TT Norms Pro';
  src: url('/fonts/TTNormsPro-Bold.woff2') format('woff2'),
  url('/fonts/TTNormsPro-Bold.woff') format('woff');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'TT Norms Pro';
  src: url('/fonts/TTNormsPro-ExtraBoldItalic.woff2') format('woff2'),
  url('/fonts/TTNormsPro-ExtraBoldItalic.woff') format('woff');
  font-weight: 800;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'TT Norms Pro';
  src:   url('/fonts/TTNormsPro-ExtraLightItalic.woff2') format('woff2'),
  url('/fonts/TTNormsPro-ExtraLightItalic.woff') format('woff');
  font-weight: 200;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'TT Norms Pro';
  src: url('/fonts/TTNormsPro-Thin.woff2') format('woff2'),
  url('/fonts/TTNormsPro-Thin.woff') format('woff');
  font-weight: 100;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'TT Norms Pro';
  src:url('/fonts/TTNormsPro-ExtraBold.woff2') format('woff2'),
  url('/fonts/TTNormsPro-ExtraBold.woff') format('woff');
  font-weight: 800;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'TT Norms Pro';
  src:url('/fonts/TTNormsPro-Regular.woff2') format('woff2'),
  url('/fonts/TTNormsPro-Regular.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'TT Norms Pro';
  src: url('/fonts/TTNormsPro-ThinItalic.woff2') format('woff2'),
  url('/fonts/TTNormsPro-ThinItalic.woff') format('woff');
  font-weight: 100;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'TT Norms Pro';
  src: url('/fonts/TTNormsPro-Light.woff2') format('woff2'),
  url('/fonts/TTNormsPro-Light.woff') format('woff');
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'TT Norms Pro';
  src: url('/fonts/TTNormsPro-MediumItalic.woff2') format('woff2'),
  url('/fonts/TTNormsPro-MediumItalic.woff') format('woff');
  font-weight: 500;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'TT Norms Pro ExtraBlack';
  src: url('/fonts/TTNormsPro-ExtraBlackItalic.woff2') format('woff2'),
  url('/fonts/TTNormsPro-ExtraBlackItalic.woff') format('woff');
  font-weight: 900;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'TT Norms Pro';
  src: url('/fonts/TTNormsPro-ExtraLight.woff2') format('woff2'),
  url('/fonts/TTNormsPro-ExtraLight.woff') format('woff');
  font-weight: 200;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'TT Norms Pro';
  src: url('/fonts/TTNormsPro-LightItalic.woff2') format('woff2'),
  url('/fonts/TTNormsPro-LightItalic.woff') format('woff');
  font-weight: 300;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'TT Norms Pro';
  src: url('/fonts/TTNormsPro-Medium.woff2') format('woff2'),
  url('/fonts/TTNormsPro-Medium.woff') format('woff');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'TT Norms Pro ExtraBlack';
  src: url('/fonts/TTNormsPro-ExtraBlack.woff2') format('woff2'),
  url('/fonts/TTNormsPro-ExtraBlack.woff') format('woff');
  font-weight: 900;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'TT Norms Pro';
  src: url('/fonts/TTNormsPro-BoldItalic.woff2') format('woff2'),
  url('/fonts/TTNormsPro-BoldItalic.woff') format('woff');
  font-weight: bold;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'TT Norms Pro';
  src: url('/fonts/TTNormsPro-BlackItalic.woff2') format('woff2'),
  url('/fonts/TTNormsPro-BlackItalic.woff') format('woff');
  font-weight: 900;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'TT Norms Pro';
  src: url('/fonts/TTNormsPro-Black.woff2') format('woff2'),
  url('/fonts/TTNormsPro-Black.woff') format('woff');
  font-weight: 900;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'TT Norms Pro';
  src: url('/fonts/TTNormsPro-Italic.woff2') format('woff2'),
  url('/fonts/TTNormsPro-Italic.woff') format('woff');
  font-weight: normal;
  font-style: italic;
  font-display: swap;
}

